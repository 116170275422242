"use strict";
(() => {
    const searchInput = document.getElementById('services-search-input');
    const table = document.querySelector('.tyre-services-table');
    if (table && searchInput) {
        const tableRows = Array.from(table.querySelectorAll('tbody > tr'));
        searchInput.addEventListener('keyup', () => {
            const value = searchInput.value.toLowerCase();
            tableRows.forEach((row) => {
                const textValue = row.textContent;
                if (textValue && textValue.indexOf(value) > -1) {
                    row.style.display = 'table-row';
                }
                else {
                    row.style.display = 'none';
                }
            });
        });
    }
})();
