"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Tabs {
    constructor(targetElement) {
        this.targetElement = targetElement;
        this.create();
    }
    create() {
        const tabsNavigation = this.targetElement.querySelector('.tabs-navigation');
        const tabsContent = this.targetElement.querySelector('.tabs-content');
        function setActiveTabElements(tabItemLinkElements, selectedTabLink, tabsContentElements, selectedTabId) {
            tabItemLinkElements.forEach((tabItemLink) => {
                var _a, _b;
                if (tabItemLink === selectedTabLink) {
                    (_a = tabItemLink.parentElement) === null || _a === void 0 ? void 0 : _a.classList.add('active');
                }
                else {
                    (_b = tabItemLink.parentElement) === null || _b === void 0 ? void 0 : _b.classList.remove('active');
                }
            });
            tabsContentElements.forEach((tabContent) => {
                if (tabContent.id === selectedTabId) {
                    tabContent.classList.add('active');
                }
                else {
                    tabContent.classList.remove('active');
                }
            });
        }
        if (tabsNavigation && tabsContent) {
            const tabItemLinkElements = Array.from(tabsNavigation.querySelectorAll('.tab-item-link'));
            const tabsContentElements = Array.from(tabsContent.querySelectorAll('.tab-content'));
            const locationHash = window.location.hash;
            if (locationHash) {
                const selectedTabId = locationHash.replace('#', '');
                const selectedTabItemLink = this.targetElement.querySelector(`a[href$=${selectedTabId}]`);
                if (selectedTabItemLink) {
                    setActiveTabElements(tabItemLinkElements, selectedTabItemLink, tabsContentElements, selectedTabId);
                }
            }
            tabItemLinkElements.forEach((tabItemLink) => {
                tabItemLink.addEventListener('click', (event) => {
                    var _a;
                    event.preventDefault();
                    const selectedTabId = (_a = tabItemLink.getAttribute('href')) === null || _a === void 0 ? void 0 : _a.replace('#', '');
                    setActiveTabElements(tabItemLinkElements, tabItemLink, tabsContentElements, selectedTabId);
                });
            });
        }
    }
}
function createTabs(element) {
    return new Tabs(element);
}
exports.default = createTabs;
